import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: {title: {eq: "about"}}) {
        html
      }
    }
  `)
  return (
    <Layout>
      <SEO title="About Elnaz" />
      <div className="mx-auto px-5 pt-0">
        <div style={{ "text-align": "justify" }}
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </div>
    </Layout>
  )
}

export default About
